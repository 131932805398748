.dropdown-overlay {
    width: 48px;
    font-size: 18px;

    .action-wrapper {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 0;
        background: none;
        margin: 0 auto;
        height: 100%;
        width: 100%;
        padding-left: 14px;
        &.action-closed {
            &:hover {
                border-bottom: 4px solid #262626;
                background-color: #f9f6f4;
                height: 68px;
            }
        }
    }
    .overlay-wrapper {
        position: relative;
        .dropdown-body {
            display: none;
            border: 2px solid rgb(30, 162, 177);
            width: 210px;
            background: white;
            position: absolute;
            right: 4px;
            top: -58px;
            border-radius: 12px;
            .action-wrapper {
                height: 48px;
                padding: 0 8px 0 18px;
            }
            &.open {
                display: block;
            }

            .dropdown-item {
                display: flex;
                justify-content: space-between;
                width: 100%;
                background: none;
                border: 0;
                font-size: 18px;
                cursor: pointer;

                &:hover {
                    background: rgb(243, 242, 242);
                    text-decoration: underline;
                }
                &--highlight {
                    background: #e1eded;
                }
                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
                .option-val {
                    padding: 12px;
                }
                .turquoise-icon {
                    padding: 6px;
                    color: rgb(30, 162, 177);
                }
            }
        }
    }
}
