$eon-red: #ea1b0a;
.wrapper {
    background-color: #ffffff;
    .content {
        margin-top: 24px;
    }
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    button,
    a {
        &:focus-visible,
        &:focus {
            outline-color: #0075c2;
        }
    }
}

$breakpoints: (
    "xsmall": 320px,
    "small": 768px,
    "medium": 1024px,
    "large": 1280px,
    "xlarge": 1535px,
) !default;

@mixin respond($breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
    }
}

.me8eon-responsive-container {
    padding-left: 24px;
    padding-right: 24px;
    @include respond(small) {
        padding-left: 32px;
        padding-right: 32px;
    }
    @include respond(medium) {
        padding-left: 48px;
        padding-right: 48px;
        max-width: 1296px;
        margin: 0 auto;
    }
    @include respond(xlarge) {
        max-width: 1536px;
    }
}

.common-button-wrapper {
    display: flex;
    margin: 24px 0;
    gap: 18px;
    flex-direction: column;
    @include respond(small) {
        flex-direction: row;
    }
}

.org-scheme {
    --primary-color: #ea1b0a;
    --heading-color: #ea1b0a;
    --heading-color-hover: #c90a01;
    --super-light-bg: #f9f6f4;
    --light-bg: #f7e8da59;
    --normal-bg: #f7e8da;
}
.org-scheme-bayernwerk {
    --primary-color: #008eb0;
    --heading-color: #008eb0;
    --heading-color-hover: #047491;
    --super-light-bg: #7fc6d712;
    --light-bg: #93abb117;
    --normal-bg: #8beaff29;
    --eon-red500: #008eb0;
    --eon-red700: #008eb0;
    .eon-ui-svg-icon, svg, path {
        fill: var(--heading-color);
        &:hover {
            svg, path {
                fill: var(--heading-color-hover);
            }
        }
    }
}