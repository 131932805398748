$eon-theme-color: rgb(234, 27, 10);
$font-size: 16px;

@keyframes zoom {
    0% {
        transform: scale(1);
    }
    10% {
        transform: scale(0.9);
    }
    99% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

.tile-component {
    margin: 0 auto;
    .drag-wrapper {
        margin: 0 auto;
    }
    .tile-text-wrapper {
        width: 100%;
        margin-left: 12px;
        text-align: initial;
    }
    .drag-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center !important;
        width: 100% !important;
        position: relative;
        margin: 8px auto;
        transition: transform 0.3s ease;
        &.highlight {
            animation: zoom 0.4s ease forwards;
        }
        .card {
            width: 100%;
            height: 76px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            transition: transform 0.2s;
            border-radius: 5px;
            .tile-body {
                text-decoration: none;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: var(--eon-neutral800);
                text-align: center;
                background-color: #fff;
                border-radius: 5px;
                &:hover {
                    background-color: var(--normal-bg);
                }
                .tile-title {
                    font-size: 16px;
                    padding: 0 8px;
                    display: -webkit-box;
                    line-clamp: 1;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                .tile-subtext {
                    font-size: 12px;
                    margin-top: 12px;
                    padding: 0 8px;
                }
                .tile-numbers {
                    font-size: 12px;
                    margin-top: 12px;
                    padding: 0 8px;
                    color: $eon-theme-color;
                }
            }
            .tile-icon {
                margin-left: 18px;
            }
            .tile-icon,
            .trash-icon {
                color: $eon-theme-color;
            }
            .close-icon {
                position: absolute;
                width: 32px;
                height: 32px;
                padding: 4px 4px 4px 9px;
                right: 10px;
                top: 22px;
                cursor: pointer;
                border: 0;
                border-radius: 16px;
                background-color: #fff;
                outline-color: #0075c2;
                &:hover {
                    background-color: #f9f6f4;
                }
                &--editmode {
                    background-color: transparent !important;
                    opacity: 0.5;
                }
            }
            &:hover {
                transform: scale(1.03);
            }
            &--movable {
                cursor: move;
                border: 1px dashed;
                .close-icon,
                .tile-body {
                    cursor: move !important;
                }
            }
            &--moving {
                .tile-body {
                    background-color: rgba(255, 255, 255, 0.8) !important;
                }
                border-color: #1ea2b1;
                .trash-icon,
                .tile-icon,
                .tile-body {
                    color: #1ea2b1;
                }
            }
        }
    }

    .TilesContainer-module_tile__1R3DP {
        width: 100% !important;
    }

    @media screen and (min-width: 440px) {
        .TilesContainer-module_tile__1R3DP {
            width: 160px !important;
        }
        .tile-text-wrapper {
            width: 100%;
            margin-left: 0px;
            text-align: center;
        }
        .drag-container {
            .card {
                width: 140px;
                height: 159px;
                margin: 10px;
                position: relative;
                .tile-body {
                    flex-direction: column;
                    padding: 0;
                    .tile-icon {
                        margin: 24px 0 8px;
                    }
                    .tile-title {
                        max-height: 58px;
                        line-clamp: 3;
                        -webkit-line-clamp: 3;
                    }
                }
                .close-icon {
                    right: 2px;
                    top: 2px;
                }
            }
        }
        .common-button-wrapper {
            flex-direction: row;
        }
    }
}
