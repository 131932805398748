.ai-button-wrapper {
    display: flex;
    // width: 160px;
    border: none;
    background: none;
    box-shadow: inset 0 0 0 2px #cccbca;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    .ai-button-label {
        margin: 4px 0 0px 8px;
        color: var(--heading-color);
        font-size: large;
        font-weight: bold;
        transition: color 0.25s ease-in-out;
        display: none;
    }
    svg {
        fill: var(--heading-color);
    }
    &:hover {
        box-shadow: inset 0 0 0 2px var(--heading-color-hover);
        .ai-button-label {
            color: var(--heading-color-hover);
            border-bottom: 2px solid var(--heading-color-hover);
        }
        svg {
            fill: var(--heading-color-hover);
        }
    }
    @media screen and (min-width: 540px) {
        .ai-button-label {
            display: inline;
        }
    }
}