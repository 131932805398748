@mixin flex-container(
    $direction: row,
    $wrap: nowrap,
    $justify: flex-start,
    $align: stretch,
    $align-content: stretch,
    $grow: 0,
    $shrink: 0,
    $basis: auto,
    $gap: 0
) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $justify;
    align-items: $align;
    align-content: $align-content;
    flex-grow: $grow;
    flex-shrink: $shrink;
    flex-basis: $basis;
    gap: $gap;
}

.advance-unified-assist {
    @include flex-container($gap: 8px, $direction: column);
    background: #F9FAFB;
    padding: 8px;
    .chatbot-response {
        overflow: hidden;
       
        .ai-logo {
            display: flex;
            align-items: center;
            font-size: 18px;
            gap: 12px;
            margin-bottom: 4px;
        }
        .show-more-button {
            display: flex;
            justify-content: center;
            margin-bottom: -8px;
            background: linear-gradient(to bottom, #fff6, #fff);
        }
        @include flex-container(
            $direction: column,
            $justify: space-between,
            $grow: 1,
            $shrink: 1,
            $basis: 80%
        );
        list-style: none;
        border: none;
        border-radius: 2px 2px 5px 5px;
        cursor: auto;
        padding: 8px;
        z-index: 1;
        color: #262626;
        min-width: 200px;
        .role-list {
            height: 50px;
            margin-bottom: 24px;
            .unified-bot-text-format {
                font-size: 16px;
                white-space: pre-wrap;
                word-break: break-word;
                a {
                    color: #1ea2b1;
                    text-decoration: none;
                }
                b {
                    font-family: "HvDTrial Brix Sans", sans-serif;
                    font-size: xx-small;
                    font-weight: normal;
                    color: #262626;
                }
            }
        }
        .unified-input-wrapper {
            display: flex;
            flex-direction: row;
            gap: 12px;
            border: 1px solid #808080;
            padding: 8px;
            max-width: 700px;
            margin: 0 auto;
            width: 100%;
        }
    }
    .no-response-loader {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        flex-basis: 80%;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        mask-image: none;
        padding: 24px;
        .chatbot-response {
            .role-list {
                height: 150px;
            }
        }
    }
}