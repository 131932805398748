/*Home$eon-theme-color: rgb(234, 27, 10);.scss*/
$eon-theme-color: rgb(234, 27, 10);
.hero-wrapper {
    position: relative;
    height: 340px;
    .bg-img-hero {
        background-image: url("../../assets/image/home_img.jpg") !important;
        height: 340px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
    }
    .content-wrapper {
        display: flex;
        justify-content: flex-end;
    }
    .welcome-content {
        text-align: right;
        padding: 20px;
        background-color: #ffffff;
        position: absolute;
        z-index: 1;
        max-width: calc(100% - 40px);
        margin-top: 20px;

        .headline-font-welcome {
            letter-spacing: 0.1px;
            text-align: right;
            font-size: 36px;
            line-height: 100%;
            font-weight: 750;
            padding: 0.1px 20px 0px 0px;
            color: $eon-theme-color;
        }
    }
    @media (min-width: 768px) {
        height: 380px;
        .bg-img-hero {
            height: 380px;
        }
        .welcome-content {
            width: 380px;
            .headline-font-welcome {
                font-size: 48px;
            }
        }
    }
    @media (min-width: 1024px) {
        height: 480px;
        .bg-img-hero {
            height: 480px;
        }
        .welcome-content {
            width: 540px;
            margin-top: 50px;
            .headline-font-welcome {
                font-size: 64px;
            }
        }
    }
}
