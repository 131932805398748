$eon-theme-color: rgb(234, 27, 10);
$font-size: 18px;

.table-font {
    font-size: $font-size;
}

.table-font-header {
    font-size: $font-size;
    font-weight: 700;
}

.action-cell {
    display: flex;
    gap: 12px;
}

.redirect-icon,
.action-icon {
    color: $eon-theme-color;
}
