@import url("./assets/eon-ui-components.css");
@font-face {
    font-family: "Eon-font";
    src: url("./assets/fonts/EON_BrixSansWeb-Regular.woff");
}

body {
    background-color: #ffffff;
}

body,
button {
    margin: 0;
    font-family: "Eon-font", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    font-size: 14px;
}
a {
    font-family: Eon-font;
}
.pre-loader-gif {
    display: flex;
    justify-content: center;
    position: static;
    margin-top: 40%;
}

.modal-data {
    color: #ea1b0a;
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
    text-align: center;
}

.mtb24 {
    margin: 24px 0;
}
