.ai-assist-content {
    .feedback {
        margin-left: 44px;
    }
}

.feedback {
    font-size: small;
    .record-feedback {
        display: flex;
        align-items: center;
    }

    & .title {
        color: var(--eon-grey800);
    }

    .thumb {
        background: transparent;
        border: unset;
        vertical-align: middle;
    }
    .thumb--selected {
        svg {
            path {
                fill: var(--eon-red500);
            }
        }
    }
    .like-dislike {
        display: flex;
        gap: 8px;
    }

    .feedback-form {
        .label-section {
            padding: 8px 0;
        }
        .feedback-tag {
            display: flex;
            gap: 12px;
            text-wrap: nowrap;
            flex-wrap: wrap;
            display: inline-block;
            padding: 4px 8px 12px 0;
            // background-color: #f2f2f2;
            color: #333;
            font-size: 14px;
            border-radius: 4px;
        }
        .text-area-wrapper {
            padding: 4px 0;
            .text-area {
                width: 100%; /* Adjust the width as needed */
                height: 80px; /* Adjust the height as needed */
                padding: 10px; /* Optional: Add some padding for better appearance */
                font-size: 16px; /* Optional: Adjust the font size */
                border: 1px solid #ccc; /* Optional: Add a border */
                border-radius: 4px; /* Optional: Add rounded corners */
                box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
            }
        }
    }
    .feedback-response {
        display: flex;
        gap: 10px;
        .feedback-message-wrapper {
            margin: 4px 0;
            .feedback-message {
                margin: 0;
            }
        }
    }
    .issue-resolver-wrapper {
        margin: 24px auto;
        .issue-resolver-content {
            display: flex;
            justify-content: center;
            gap: 8px;
            margin: 12px auto;
            .differentiator {
                padding-top: 5px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .issue-resolver-wrapper {
            .issue-resolver-content {
                gap: 24px;
            }
        }
    }
}
