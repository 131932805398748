$card-bg-color: #fafafa;
$eon-icon-color: var(--heading-color);
$color-white: #fff;
$color-black: #000;

.ai-assist-wrapper {
    background-color: $card-bg-color;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    min-height: calc(100vh - 385px);
    padding: 16px;
    margin-top: 16px;
    .ai-assit-container {
        flex: 3;
    }
    .ai-assist-welcome {
        text-align: center;
        .start-message {
            color: #404040;
            font-size: 32px;
            font-weight: 500;
        }
    }
    .user-header,
    .bot-header {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-bottom: 12px;
        border-radius: 8px;
        padding: 12px;
        background: #f1f2f6;
        .ai-action-btn-wrapper {
            display: flex;
            gap: 12px;
            margin: 12px 0;
        }
        .user-reply-container,
        .bot-reply-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 90%;
            .user-heading,
            .bot-heading {
                margin: 6px 0;
                font-weight: bold;
            }
            .bot-heading {
                color: $eon-icon-color;
            }
            .ticket-container {
                display: flex;
                flex-direction: column;
                .ticket-description {
                    background: #f8f5f2;
                    padding: 12px;
                }
                .message-content {
                    padding: 12px;
                }
                .ticket-success,
                .failure-msg {
                    display: flex;
                    gap: 8px;
                    padding: 0 8px;
                }
                .last-message {
                    margin: 8px;
                }
            }
            .bot-content {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding-bottom: 12px;
                .heading {
                    font-size: 16px;
                    font-weight: 700;
                }
                .list {
                    display: flex;
                    gap: 12px;
                    max-width: max-content;
                    .list-item {
                        font-size: 12px;
                        color: #1F2937;
                        padding: 8px;
                    }
                }
            }
            .non-it-warning {
                color: #262626;
                .question {
                    font-weight: 700;
                }
                .recommendation {
                    font-size: 12px;
                    color: #5c5c5c;
                    font-weight: 700;
                }
                .recommendation-text {
                    font-size: 12px;
                    color: #5c5c5c;
                }
            }
        }
    }
    .user-header {
        align-items: center;
        .bot-text {
            font-size: 18px;
        }
    }
    .bot-header {
        background: #fff;
        font-size: 16px;
    }
    .bot-text {
        font-size: 16px;
        white-space: pre-wrap;
        word-break: break-word;
        a {
            color: #1ea2b1;
            text-decoration: none;
        }
        b {
            font-family: "HvDTrial Brix Sans", sans-serif;
            font-size: xx-small;
            font-weight: normal;
            color: #262626;
        }
    }
    .ai-assist-right-panel-desktop {
        display: none;
    }
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        padding: 24px;
        margin-top: 24px;
        gap: 24px;
        position: relative;
        .ai-assit-container {
            flex: none;
            width: calc(100% - 324px)
        }
        .ai-assist-right-panel-desktop {
            display: flex;
            flex-direction: column;
            gap: 24px;
            align-items: center;
            width: 300px;
            max-height: fit-content;
            position: sticky;
            top: 100px;
        }
        .user-header, .bot-header {
            .user-reply-container, .bot-reply-container {
                .bot-content {
                    flex-direction: row;
                }
            }
        }
    }
}
.ai-assist-right-panel-mobile {
    display: flex;
    gap: 12px;
    background: #F9F6F4;
    padding: 12px 16px;
    @media screen and (min-width: 1024px) {
        display: none;
    }
}
.button-toggle-desktop {
    display: none;
    background: #FFF;
    border-radius: 8px;
    padding: 4px;
    width: fit-content;
    .opt-button {
        color: #404040;
        padding: 4px 8px;
        border-radius: 8px;
        background: transparent;
        border: 0;
        border: 2px solid transparent;
        min-width: 30px;
        cursor: pointer;
        &--active {
            color: #262626;
            // border-color: #bfbfbf;
            background: #F0EBE5;
            box-shadow: 0px 0px 2px 0px #00000080;
        }
    }
    @media screen and (min-width: 1024px) {
        display: block;
    }

}
.button-toggle-mobile {
    display: block;
    width: 50%;
    @media screen and (min-width: 1024px) {
        display: none;
    }
}
.ai-assist-footer {
    margin: 0 auto;
    position: sticky;
    bottom: 0;
    display: flex;
    gap: 12px;
    flex-direction: column;
    background: linear-gradient(to bottom, #fff6, #fff);
    padding: 8px 0;
    .user-heading {
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: bold;
    }
    .input-wrapper {
        display: flex;
        flex-direction: row;
        gap: 12px;
        background-color: #fff;
        max-width: 700px;
        margin: 0 auto;
        width: 100%;
    }
    .search-list-component {
        background-color: $color-white;
    }
    @media screen and (min-width: 1024px) {
        margin: 0;
        width: calc(75% - 8px);
    }
}
.disclaimer-text {
    color: #5c5c5c;
    text-align: center;
    @media screen and (min-width: 1024px) {
        margin: 0;
        width: calc(75% - 8px);
    }
}
