.tilePage {
    .edit-mode--opaque {
        opacity: 0.5;
        pointer-events: none;
    }
    .tile-width--limited {
        max-width: 700px;
        margin: 0 auto;
        text-align: center;

        .tile-heading {
            color: var(--heading-color);
        }

        .search-wrapper {
            display: flex;
            gap: 2px;
        }
    }
    .tile-background {
        background-color: var(--super-light-bg);
        padding: 12px 16px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}
.search-wrapper {
    display: flex;
    justify-content: space-between;
    .new-ai-chat {
        color: #262626;
        justify-content: flex-end;
        display: flex;
        background-color: #fff;
        border: none;
        cursor: pointer;
        .add-new-ai-chat {
            padding-left: 8px;
            margin: auto;
            font-weight: bold;
            &:hover {
                color: var(--heading-color);
                background-color: #f9f6f4;
            }
        }
        .icon-wrapper {
            margin: auto;
        }
        &:hover {
            color: var(--heading-color);
            background-color: #f9f6f4;
            border-bottom: 2px solid var(--heading-color-hover);
        }
    }
}