.search-item-row {
    border-bottom: 1px solid var(--eon-grey300);
    padding: 12px;
    font-size: 14px;
    word-break: break-word;

    &:hover {
        background: var(--eon-grey100);
    }

    .row-1 {
        display: flex;
        font-size: 16px;
        margin-bottom: 4px;
        .search-item-logo {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            object-fit: cover;
        }

        &.links {
            display: flex;
            flex-direction: column;
            margin-inline: 8px;
        }
    }
    .row-2 {
        display: flex;
        gap: 24px;
        align-items: center;
        color: var(--eon-grey700);
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 8px;

        & span {
            .value {
                color: var(--eon-grey900);
                font-size: 14px;
            }
        }
    }
    .row-3 {
        font-size: 16px;
        color: var(--eon-grey800);
        font-weight: 400;
        padding-block: 4px;

        p {
            margin: 4px 0;
        }
        td,
        th {
            border: 1px solid #9d9d9d;
            min-width: 100px;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 19.12px;
            font-size: 16px;
            color: var(--eon-grey800);
            font-weight: 400;
        }

        & .image-content {
            width: 96px;
            height: auto;

            & img {
                height: 96px;
                object-fit: contain;
            }
        }
    }
    .data-type {
        font-size: 14px;
        padding-top: 4px;
    }
    .search-item-link,
    .search-item-link-full {
        color: var(--eon-grey900);
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 8px;
        &:hover {
            color: var(--eon-red500);
        }
    }
    .search-item-link-full {
        width: 100%;
        display: block;
        color: var(--eon-grey500);
        font-weight: 400;
        font-size: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    & .meta-data-list {
        display: flex;
        gap: 24px;

        & .key-value {
            display: flex;
            flex-direction: column;

            & .key {
                font-size: 12px;
                font-weight: 400;
                color: var(--eon-grey700);
                text-transform: capitalize;
            }

            & .value {
                font-size: 14px;
                font-weight: 400;
                color: var(--eon-grey900);
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .row-1 {
            .search-item-logo {
                height: auto;
            }
        }
    }
}
