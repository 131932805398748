.related-apps-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    background-color: #F8F5F2;
    color: #262626;
    overflow: hidden;
    .related-apps-ul {
        padding: 0;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin: 0;
        .related-apps-li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
            list-style: none;
            border: 0.5px solid #E7E8EC;
            padding: 8px 12px;
            border-radius: 4px;
            background: white;
            width: -webkit-fill-available;
            .related-apps-link {
                width: max-content;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                    text-decoration-color: #000000;                    
                }
                .related-apps-label {
                    flex: 1;
                    list-style: none;
                    margin: 0;
                    font-size: 12px;
                    color: #262626;
                }
            }
            .add-icon {
                border: 0;
                position: relative;
                right: 0px;
                border-radius: 16px;
                background-color: #f9f6f4;
                margin-left: 2px;
                width: 32px;
                height: 32px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background-color: #f7e8da;
                }
            }
        }
    }
    .related-apps-title {
        font-size: 14px;
        font-weight: 500;
    }
    .app-item {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
        .app-item-name {
            margin-right: 10px;
        }
    }
    .view-more-apps {
        display: none;
    }
    
    @media screen and (min-width: 1024px) {
        width: 260px;
        height: auto;
        .related-apps-ul {
            flex-direction: column;
            .related-apps-li {
                .related-apps-link {
                    width: auto;
                    .related-apps-label {
                        font-size: 14px;
                    }
                }
            }
        }
        .related-apps-title {
            font-size: 16px;
            font-weight: 700;
        }
        .view-more-apps {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            .view-more-icon {
                margin-left: 5px;
            }
        }
    }
}
