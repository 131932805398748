.search-filter-wrapper {
    color: #262626;
    .search-filter-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #B3ACA9;
        padding: 12px 0;
        
        .filter-header-left {
            width: max-content;
            display: flex;
            align-items: center;
            gap: 12px;
            font-size: 18px;
            font-weight: 900;
            cursor: pointer;
        }
        .arrow-container {
            display: flex;
            gap: 10px;

            .filter-count {
                align-content: center;
                font-size: 18px;
                font-weight: 700;
                color: var(--primary-color);
            }
        }
        .filter-heading {
            font-size: 18px;
            font-weight: 700;
        }
    }
    .filter-list-wrapper {
        .filter-opt {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            align-items: center;
            gap: 24px;
            font-size: 16px;
            min-height: 48px;
            .current-opt-left {
                display: flex;
                gap: 12px;
                align-items: center;
            }
            &--selected,
            &:hover,
            &:focus {
                background-color: #f8f5f2;
                text-decoration: underline;
                color: var(--primary-color);
                cursor: pointer;
            }
            &--selected {
                background-color: #f8f5f2;
                &:hover {
                    background-color: #f8f5f2;
                }
            }
            input[type="radio"]:checked {
                accent-color: var(--primary-color);
            }

            input[type="radio"]:checked + label {
                color: var(--primary-color);
            }
        }
    }

    .refine-filters {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .filter-section-heading {
        font-size: 16px;
        font-weight: 700;
        margin: 10px 0;
    }

    .filter-search-section {
        display: none;
    }

    .reload-icon {
        cursor: pointer;
    }

    @media screen and (min-width: 1024px) {
        width: 286px;
        .search-filter-header {
            .filter-header-left {
                cursor: default;
            }
            .arrow-container {
                display: none;
            }
        }
        .filter-search-section {
            display: block;
        }
    }
}

.search-filters-modal-container {
    .btn {
        display: flex;
        gap: 20px;
        margin-top: 20px;
    }
    .search-filter-wrapper {
        .search-filter-header {
            .arrow-container {
                display: none;
            }
        }
    }
    .filter-search-section {
        display: block;
    }

    @media screen and (min-width: 320px) {
        width: 200px;
    }

    @media screen and (min-width: 480px) {
        width: 260px;
    }
}