.link-section {
    background: #f8f5f2;
    padding: 12px;
    .link-heading {
        margin-bottom: 4px;
        font-weight: bold;
    }
    .link-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        .link {
            margin: 8px 0;
            word-break: break-all;
            a {
                color: #1ea2b1;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .link-buttons {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }
}
.ai-response {
    margin: 0;
}

.citation-container {
    flex: 1;
    .citation-item {
        margin: 10px;
    }
}
.iframe-with-links {
    display: flex;
}
.pdf-preview {
    flex: 2;
    width: 100%;
    min-width: 400px;
    min-height: 600px;
    @media screen and (max-width: 920px) {
        display: none;
    }
}
